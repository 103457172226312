import css from './Wrapper.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BalloonBgPosition, BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { PerformerType } from 'meta/pages/performer'

import Menu, { MenuSkeleton } from 'components/BackendControlled/Component/Menu'
import { GridColumn, GridContainer } from 'components/Layout/Grid'
import CollectionBalloon from 'components/Pages/Collection/Balloon/CollectionBalloon'
import CollectionDetails from 'components/Pages/Collection/CollectionDetails'
import CollectionDetailsSkeleton from 'components/Pages/Collection/CollectionDetails/CollectionDetailsSkeleton'
import EventList from 'components/Pages/Collection/EventList'
import EventListSkeleton from 'components/Pages/Collection/EventList/EventListSkeleton'
import InfoBox from 'components/Pages/Collection/InfoBox'
import SeasonList from 'components/Pages/Collection/SeasonList'
import PerformerHead from 'components/Pages/Performer/PerformerHead'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { usePerformerDetails, usePerformerEvents, usePerformerSeasons } from 'hooks/usePerformer'

import { getPerformersMenuItems, isPerformerArtist } from './utils'

interface Props {
  id: string
}

const Wrapper: React.FC<Props> = (props) => {
  const { id } = props

  const { t } = useTranslation()

  const language = useCurrentLanguage()

  const details = usePerformerDetails({ id, language })
  const events = usePerformerEvents({ id, language })
  const seasons = usePerformerSeasons({ id, language })

  return (
    <div className={css.collectionWrapper}>
      {details && <PerformerHead id={id} />}
      <GridContainer>
        <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
          <CollectionBalloon
            className={css.balloonWrapper}
            backgroundUrl={details && details?.cover?.background?.sources[0]?.url}
            color={ComponentColor.black}
            size={BalloonSize.medium}
            backgroundPosition={BalloonBgPosition.center}
            tipPosition={BalloonTipPosition.right}
            containsMenu
          >
            {details && (
              <>
                <CollectionDetails
                  // fbPixel={details.fbPixel}
                  name={details.name}
                  id={id}
                  cover={{
                    imgUrl: details?.cover?.square?.sources[0]?.url,
                    name: details.name,
                    type: details.type,
                  }}
                  header={{
                    title: details.name,
                    tags: details.tags,
                    type: details.type,
                  }}
                />
                {
                  <Menu
                    underlineFirst
                    insideBalloon
                    isSticky
                    list={getPerformersMenuItems(details, events ?? [], seasons ?? [], t)}
                  />
                }
              </>
            )}

            {!details && (
              <>
                <CollectionDetailsSkeleton />
                <MenuSkeleton list={[]} />
              </>
            )}
          </CollectionBalloon>
          {seasons && seasons?.length > 0 && (
            <SeasonList collectionId={id} anchorId={'id_abbonamenti'} seasonTickets={seasons} />
          )}
          {events && <EventList anchorId={'id_eventi'} events={events} isPerformer />}
          {!events && <EventListSkeleton />}
        </GridColumn>
      </GridContainer>
      {details && (
        <InfoBox
          galleryData={details.gallery}
          descData={{
            name: details.name,
            description: details.bio,
            social: details.externalUris,
            isArtist: isPerformerArtist(details.type.toUpperCase() as PerformerType),
          }}
          anchorId={'id_info'}
          bgImgUrl={details.gallery[0]?.url}
          isPerformer
        />
      )}
    </div>
  )
}

export default Wrapper
