import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Head from 'components/common/Head'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { usePerformerDetails, usePerformerMetadata } from 'hooks/usePerformer'

interface Props {
  id: string
}

const PerformerHead: React.FC<Props> = (props) => {
  const { id } = props

  const { t } = useTranslation()

  const language = useCurrentLanguage()

  const details = usePerformerDetails({ id, language })

  const performerMetadata = usePerformerMetadata({ id, language })

  const title = String(
    t('collection:performer_meta_title', {
      name: details?.name,
      type: String(t(`collection:performer_meta_type_${details?.type.toUpperCase()}`)),
    })
  )

  const description = String(
    t('collection:performer_meta_description', {
      name: details?.name,
      type: String(t(`collection:performer_meta_type_${details?.type.toUpperCase()}`)),
    })
  )

  useEffect(() => {
    if (performerMetadata) {
      window.prerenderReady = true
    }
  }, [performerMetadata])

  useEffect(() => {
    window.prerenderReady = false
  }, [])

  return (
    <>
      <Head>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />

        <title>{title}</title>
      </Head>

      {performerMetadata && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(performerMetadata)}`,
          }}
        />
      )}
    </>
  )
}

export default PerformerHead
