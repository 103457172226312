import React, { useEffect, useRef } from 'react'

import { CollectionInfoBoxDescription } from 'meta/pages/collection'

import useOnScreen from './useOnScreen'

interface Props {
  num: number
  url: string
  setVisible(num: number): void
  descData: CollectionInfoBoxDescription
}

const Item: React.FC<Props> = (props) => {
  const { num, url, setVisible, descData } = props

  const ref = useRef<HTMLImageElement>(null)
  const isVisible = useOnScreen(ref)

  const alt = `${descData.name}_photo`

  useEffect(() => {
    if (isVisible) {
      setVisible(num)
    }
  }, [isVisible])

  return <img ref={ref} src={url} alt={alt} />
}

export default Item
