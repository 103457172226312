import css from './Wrapper.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { BalloonBgPosition, BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import Menu, { MenuSkeleton } from 'components/BackendControlled/Component/Menu'
import { GridColumn, GridContainer } from 'components/Layout/Grid'
import { useCollectionDetails } from 'hooks/useCollection'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

import CollectionBalloon from '../Balloon/CollectionBalloon'
import CollectionDetails from '../CollectionDetails'
import CollectionDetailsSkeleton from '../CollectionDetails/CollectionDetailsSkeleton'
import GoBack from '../CollectionIFrame/GoBack/GoBack'
import EventList from '../EventList/EventList'
import EventListSkeleton from '../EventList/EventListSkeleton'
import InfoBox from '../InfoBox'
import SeasonList from '../SeasonList/SeasonList'
import { getCollectionsMenuItems } from './utils'

interface Props {
  id: string
  fromIframe?: boolean
}

const Wrapper: React.FC<Props> = (props) => {
  const { id, fromIframe } = props

  const { t } = useTranslation()

  const language = useCurrentLanguage()

  const details = useCollectionDetails({ id, language })

  const [showContent, setShowContent] = useState<boolean>(true)

  const dispatch = useAppDispatch()

  const toggleContent = () => {
    dispatch(CheckoutActions.close())
    setShowContent(!showContent)
  }

  return (
    <div className={classNames(css.collectionWrapper, { [css.fromIframe]: Boolean(fromIframe) })}>
      {!showContent && (
        <GridContainer>
          <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
            <GoBack toggleContent={toggleContent} />
          </GridColumn>
        </GridContainer>
      )}
      {showContent && (
        <GridContainer>
          <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
            {!fromIframe && (
              <CollectionBalloon
                className={css.balloonWrapper}
                backgroundUrl={details && details.coverImages.filter((x) => x.format == 'background')[0]?.url}
                color={ComponentColor.black}
                size={BalloonSize.medium}
                backgroundPosition={BalloonBgPosition.center}
                tipPosition={BalloonTipPosition.right}
                containsMenu
              >
                {details && (
                  <>
                    <CollectionDetails
                      fbPixel={details.fbPixel}
                      name={details.name}
                      id={id}
                      cover={{
                        imgUrl: details.coverImages.filter((x) => x.format == 'square')[0]?.url,
                        name: details.name,
                      }}
                      header={{
                        title: details.name,
                        tags: details.tags,
                        location: details.location,
                      }}
                    />
                    {<Menu underlineFirst insideBalloon isSticky list={getCollectionsMenuItems(details, t)} />}
                  </>
                )}

                {!details && (
                  <>
                    <CollectionDetailsSkeleton />
                    <MenuSkeleton list={[]} />
                  </>
                )}
              </CollectionBalloon>
            )}
            {details && (
              <>
                {details.typeTicketCollections?.length > 0 && (
                  <SeasonList
                    collectionId={id}
                    anchorId={'id_abbonamenti'}
                    seasonTickets={details.typeTicketCollections}
                    fromIframe={Boolean(fromIframe)}
                    toggleContent={toggleContent}
                  />
                )}
                {details.events?.length > 0 && (
                  <EventList
                    anchorId={'id_eventi'}
                    events={details.events}
                    fromIframe={Boolean(fromIframe)}
                    toggleContent={toggleContent}
                  />
                )}
              </>
            )}
            {!details && <EventListSkeleton />}
          </GridColumn>
        </GridContainer>
      )}
      {details && !fromIframe && (
        <InfoBox
          galleryData={details.galleryImages}
          descData={{
            name: details.name,
            description: details.longDescription,
            social: details.links,
          }}
          anchorId={'id_info'}
          bgImgUrl={details.galleryImages.filter((x) => x.format == 'gallery')[0]?.url}
        />
      )}
    </div>
  )
}

export default Wrapper
