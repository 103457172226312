import css from './Coverbox.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

// import { useTranslation } from 'react-i18next'
import { ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

// import { ButtonWhiteBlack } from 'components/Actions/Action'
import Card, { CardImageType } from 'components/Layout/Card'
import { GridColumn } from 'components/Layout/Grid'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { ButtonShare } from 'components/Social'
import { useUrlCloudfront } from 'hooks/useEnv'

interface Props {
  imgUrl: string
  name: string
  type?: string
}

const CoverBox: React.FC<Props> = (props) => {
  const { imgUrl, name, type } = props

  const urlCloudfront = useUrlCloudfront()

  const { t } = useTranslation()

  const isPerformer = Boolean(type)

  const backgroundImage = imgUrl
    ? imgUrl
    : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`

  const title = isPerformer
    ? String(
        t('collection:performer_share_title', {
          name: name,
          type: String(t(`collection:performer_meta_type_${type?.toUpperCase()}`)),
        })
      )
    : undefined

  return (
    <GridColumn
      className={css.coverBoxGridContainer}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.laptop]: 13 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 17 }}
    >
      <div className={classNames(css.coverBoxContainer, { [css.isPerformer]: isPerformer })}>
        <Card className={css.card} imageType={CardImageType.cover}>
          <ImgSkeleton alt={name} src={backgroundImage} className={css.image_skeleton} />
          <div className={css.share}>
            <ButtonShare
              noBorder={!isPerformer}
              color={isPerformer ? ComponentColor.black : ComponentColor.white}
              name={name}
              title={title}
            />
          </div>
        </Card>
        {/* <ButtonWhiteBlack className={css.button} onClick={() => {}}>
          <h5>{t('collection:follow')}</h5>
        </ButtonWhiteBlack> */}
      </div>
    </GridColumn>
  )
}

export default CoverBox
