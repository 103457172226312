import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataDogLogType, LOGS_CHECKOUT_PROCESS_START } from 'meta/logger'
import { QueryParams, Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { DataDogLogger } from 'helpers/debug/logger'

import { useIsAppInitialized } from 'hooks/useApp'
import { useQueryParams } from 'hooks/useQueryParams'

export const useStartProcess = (): void => {
  const appInitialized = useIsAppInitialized()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const paymentGatewayData = useQueryParams<QueryParams['CheckoutProcess']>()
  const { transactionCode, paymentGateway } = paymentGatewayData

  useEffect(() => {
    if (!appInitialized) return

    if (!transactionCode || !paymentGateway) {
      navigate(Routes.Error.generatePath())
      return
    }

    // log to datadog
    DataDogLogger(LOGS_CHECKOUT_PROCESS_START, DataDogLogType.info, { transactionCode, paymentGateway })
    // start checkout process
    dispatch(CheckoutActions.startProcess({ transactionCode, paymentGateway, paymentGatewayData }))
  }, [appInitialized])
}
