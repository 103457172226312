import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Props = {
  id: string
}

type Response = {
  performerSchema: string
}

export const getMetadata = async (props: Props): Promise<object | undefined> => {
  const { id } = props

  try {
    const { data } = await HttpRequest.get<Response>(ApiEndpoint.performer.metadata(id))
    return data ? JSON.parse(data.performerSchema) : undefined
  } catch (error) {
    window.prerenderReady = true

    return undefined
  }
}
