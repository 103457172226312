import { CollectionAsset, CollectionLink, CollectionTag } from './collection'

export interface PerformerCoverAsset {
  square: CollectionAsset
  background: CollectionAsset
}

export interface PerformerGalleryAsset {
  gallery: CollectionAsset[]
}

export interface IPerformer {
  id: number
  name: string
  code: string
  type: PerformerType
  bio: string
  cover: PerformerCoverAsset
  gallery: CollectionAsset[]
  tags: CollectionTag[]
  externalUris: CollectionLink[]
}

export enum PerformerType {
  ACTOR = 'ACTOR',
  BAND = 'BAND',
  CIRCUS_COMPANY = 'CIRCUS_COMPANY',
  DJ = 'DJ',
  FORMAT = 'FORMAT',
  SINGER = 'SINGER',
  SPORT_TEAM = 'SPORT_TEAM',
  TROUPE = 'TROUPE',
}
