import css from './Header.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionLocation, CollectionTag } from 'meta/pages/collection'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

interface Props {
  title: string
  tags: CollectionTag[]
  location?: CollectionLocation
  type?: string
}

const Header: React.FC<Props> = (props) => {
  const { title, tags, location, type } = props

  const isPerformer = Boolean(type)

  const { t } = useTranslation()

  return (
    <GridColumn gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 12 }}>
      <div className={css.headerContainer}>
        {location && !isPerformer && (
          <p className={css.location}>
            <span className={css.marker}>
              <Img src="/icons/location_marker_transparent_white.svg" />
            </span>
            <span
              className={css.address}
            >{`${t('collection:collection_of')}${location.city} (${location.province})`}</span>
          </p>
        )}
        {isPerformer && (
          <p className={css.performer}>
            <span>{String(t(`collection:type_${type?.toUpperCase()}`))}</span>
          </p>
        )}
        <h1 className={css.title}>{title}</h1>
        <div className={css.tags}>
          {tags.map((elem, key) => (
            <span key={key} className={css.tag}>
              {elem.name ? `#${elem.name}` : elem.tag}
            </span>
          ))}
        </div>
      </div>
    </GridColumn>
  )
}

export default Header
