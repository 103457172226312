import { CollectionEvent, CollectionTypeTicket } from 'meta/pages/collection'
import { IPerformer } from 'meta/pages/performer'
import { useAppSelector } from 'store'
import { PerformerSelectors } from 'store/pages/performer'

export const usePerformerDetails = ({ id, language }: { id: string; language: string }): IPerformer | undefined =>
  useAppSelector(PerformerSelectors.getPerformerDetails({ id, language }))

export const usePerformerEvents = ({ id, language }: { id: string; language: string }): CollectionEvent[] | undefined =>
  useAppSelector(PerformerSelectors.getPerformerEvents({ id, language }))

export const usePerformerSeasons = ({
  id,
  language,
}: {
  id: string
  language: string
}): CollectionTypeTicket[] | undefined => useAppSelector(PerformerSelectors.getPerformerSeasons({ id, language }))

export const usePerformerMetadata = ({ id, language }: { id: string; language: string }): object | undefined =>
  useAppSelector(PerformerSelectors.getPerformerMetadata({ id, language }))
