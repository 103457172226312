import { getDetails } from 'controller/performer/getDetails'
import { getEvents } from 'controller/performer/getEvents'
import { getMetadata } from 'controller/performer/getMetadata'
import { getSeasons } from 'controller/performer/getSeasons'

export const PerformerController = {
  getDetails,
  getEvents,
  getSeasons,
  getMetadata,
}
