import { createAsyncThunk } from '@reduxjs/toolkit'

import { CollectionTypeTicket } from 'meta/pages/collection'
import { PerformerController } from 'controller/performer/controller'
import { RootState } from 'store/types'

type Params = {
  id: string
}

type Returned = {
  id: string
  seasons: CollectionTypeTicket[]
}

export const fetchSeasons = createAsyncThunk<Returned, Params, { state: RootState }>(
  'performer/fetchSeasons',
  async (params) => {
    const { id } = params

    const seasons = await PerformerController.getSeasons({ id })

    return { id, seasons }
  }
)
