import { CollectionEvent, CollectionTypeTicket } from 'meta/pages/collection'

import { DetailsParams } from './slice/types'

type Path = string

export type PerformerState = Record<
  Path,
  {
    details?: DetailsParams
    events?: CollectionEvent[]
    seasons?: CollectionTypeTicket[]
    metadata?: object
  }
>

export const initialState: PerformerState = {}
