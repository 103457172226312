import { createAsyncThunk } from '@reduxjs/toolkit'

import { PerformerController } from 'controller/performer/controller'
import { RootState } from 'store/types'

type Params = {
  id: string
}

type Returned = {
  id: string
  metadata?: object
}
export const fetchMetadata = createAsyncThunk<Returned, Params, { state: RootState }>(
  'performer/metadata/fetch',
  async (params) => {
    const { id } = params

    const metadata = await PerformerController.getMetadata({ id })

    return { id, metadata }
  }
)
