import { TFunction } from 'i18next'
import { ComponentType, IMenuItem } from 'meta/backendControlled/structure'
import { CollectionEvent, CollectionTypeTicket } from 'meta/pages/collection'
import { IPerformer, PerformerType } from 'meta/pages/performer'

export const getPerformersMenuItems = (
  details: IPerformer,
  events: CollectionEvent[],
  seasons: CollectionTypeTicket[],
  t: TFunction
): IMenuItem[] => {
  const menuItems: IMenuItem[] = []

  if (!details) {
    return menuItems
  }

  if (seasons?.length > 0) {
    menuItems.push({
      title: t('collection:season_tickets_title'),
      anchor: 'abbonamenti',
      componentType: ComponentType.map,
    })
  }

  if (events?.length > 0) {
    menuItems.push({
      title: t('collection:event_title'),
      anchor: 'eventi',
      componentType: ComponentType.map,
    })
  }

  if (details.bio) {
    menuItems.push({
      title: t(isPerformerArtist(details.type) ? 'collection:info_history_title' : 'collection:biography'),
      anchor: 'info',
      componentType: ComponentType.map,
    })
  }

  // {
  //   title: 'FAQ',
  //   anchor: 'faq',
  //   componentType: ComponentType.map,
  // },
  // {
  //   title: 'Mag',
  //   anchor: 'mag',
  //   componentType: ComponentType.map,
  // },
  // {
  //   title: 'Potrebbe interessarti',
  //   anchor: 'potrebbe',
  //   componentType: ComponentType.map,
  // },

  return menuItems
}

export const isPerformerArtist = (type: PerformerType): boolean => {
  return [PerformerType.ACTOR, PerformerType.BAND, PerformerType.SINGER].includes(type)
}
