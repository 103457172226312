import { CollectionEvent } from 'meta/pages/collection'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Params = {
  id: string
}

export const getEvents = async (params: Params): Promise<CollectionEvent[]> => {
  const { id } = params

  const { data } = await HttpRequest.get<CollectionEvent[]>(ApiEndpoint.performer.events(id))

  return data
}
