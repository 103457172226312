import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { RouteParams, Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { PerformerActions } from 'store/pages/performer/actions'

import withTranslation from 'components/HOC/WithTranslation'
import { useIsAppInitialized } from 'hooks/useApp'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

import Wrapper from './Wrapper/Wrapper'

const Performer: React.FC = () => {
  const { id } = useParams<RouteParams['Performer']>()

  const dispatch = useAppDispatch()
  const appInitialized = useIsAppInitialized()

  const language = useCurrentLanguage()

  useEffect(() => {
    if (appInitialized) {
      id && dispatch(PerformerActions.fetchData({ id }))
      id && dispatch(PerformerActions.fetchEvents({ id }))
      id && dispatch(PerformerActions.fetchSeasons({ id }))
      id && dispatch(PerformerActions.fetchMetadata({ id }))
    }
  }, [id, dispatch, appInitialized, language])

  if (!id) {
    return <Navigate to={Routes.Home.generatePath()} />
  }

  return <Wrapper id={id} />
}

const namespaces = ['collection', 'event']

export default withTranslation(namespaces)(Performer)
