import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { RouteParams, Routes } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'

import BackendControlled from 'components/BackendControlled'
import withTranslation from 'components/HOC/WithTranslation'

const Event: React.FC = () => {
  const { codeUrl } = useParams<RouteParams['Event']>()

  if (!codeUrl) {
    return <Navigate to={Routes.Home.generatePath()} />
  }

  return (
    <BackendControlled
      // banner={{
      //   imageUrl: '/banner/banner_header_event.png',
      //   imageMobileUrl: '/banner/banner_header_event_mobile.png',
      //   type: BannerType.top,
      // }}
      bannerTop={{
        imageURLs: {
          desktop: `/banner/banner_2024_header_home_desktop.png`,
          laptop: `/banner/banner_2024_header_home_laptop.png`,
          mobile: `/banner/banner_2024_header_home_mobile.png`,
          tabletLandscape: `/banner/banner_2024_header_home_tablet_landscape.png`,
          tabletPortrait: `/banner/banner_2024_header_home_tablet_portrait.png`,
        },
      }}
      path={ApiEndpoint.event.components(codeUrl)}
    />
  )
}

const namespaces = ['event', 'faq']

export default withTranslation(namespaces)(Event)
