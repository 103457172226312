import css from './LoginV2.module.scss'
import React from 'react'

import { LoginStep } from 'meta/pages/login'
import { useAppDispatch } from 'store'
import { AuthActions } from 'store/auth'
import { CookieClient, CookieName } from 'utils/cookie'

import { useLoginStep } from 'hooks/useLogin'

import { Grid } from '../../Layout/Grid'
import Almost from './Steps/Almost'
import Done from './Steps/Done'
import Email from './Steps/Email'
import EmailConfirm from './Steps/EmailConfirm'
import None from './Steps/None'
import OTP from './Steps/OTP'
import Phone from './Steps/Phone'
import WhenWhere from './Steps/WhenWhere'
import Who from './Steps/Who'
import ButtonsHeader from './ButtonsHeader'
import { IPropsLogin } from './props'
import RegisterStepper from './RegisterStepper'

type LoginGridProps = IPropsLogin & {
  dataTestId: string
}

const stepComponents = {
  [LoginStep.none]: None,
  [LoginStep.email]: Email,
  [LoginStep.phone]: Phone,
  [LoginStep.otp]: OTP,
  [LoginStep.who]: Who,
  [LoginStep.whenWhere]: WhenWhere,
  [LoginStep.almost]: Almost,
  [LoginStep.done]: Done,
  [LoginStep.emailConfirm]: EmailConfirm,
}

const LoginGrid: React.FC<LoginGridProps> = (props) => {
  const { dataTestId, fromModal, onClose, onComplete } = props

  const dispatch = useAppDispatch()
  const step = useLoginStep()

  const onSuccess = (token: string) => {
    // set cookie and redirect if needed
    CookieClient.set(CookieName.TOKEN, token, { expires: 30 })

    // update store
    dispatch(AuthActions.login({ token }))

    onComplete && onComplete()
  }

  return (
    <Grid className={css.login} data-testid={dataTestId}>
      {/* @ts-ignore */}
      <ButtonsHeader fromModal={fromModal} onClose={onClose} />
      {React.createElement(stepComponents[step], { fromModal, onSuccess })}
      <RegisterStepper />
    </Grid>
  )
}

export default LoginGrid
