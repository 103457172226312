import css from './InfoDescription.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionInfoBoxDescription } from 'meta/pages/collection'

import Description from 'components/BackendControlled/Component/common/Description'
import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

import { MockCollectionAnchorable } from '../../mock'

const InfoDescription: React.FC<CollectionInfoBoxDescription & MockCollectionAnchorable> = (props) => {
  const { anchorId, name, description, social, isArtist } = props

  const { t } = useTranslation()

  const socialIconImg: { [key: string]: string } = {
    facebook: '/social/collection-social-facebook.svg',
    website: '/social/collection-social-website.svg',
    instagram: '/social/collection-social-instagram.svg',
    tikTok: '/social/collection-social-tiktok.svg',
    spotify: '/social/collection-social-spotify.svg',
  }

  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.tablet]: 2, [Breakpoints.mobile]: 1 }}
      gridColumnEnd={{ [Breakpoints.tablet]: 9, [Breakpoints.mobile]: 17 }}
    >
      <div className={css.descriptionContainer}>
        <h2 className={css.header} id={anchorId}>
          {t(isArtist ? 'collection:biography' : 'collection:info_title')}
        </h2>
        <h3 className={css.artistName}>{name}</h3>

        <div className={css.description}>
          <Description description={description} />
        </div>
        <div className={css.social}>
          <Grid>
            {social &&
              social.map((elem, key) => {
                if (!socialIconImg[elem.type]) {
                  return null
                }

                return (
                  <GridColumn
                    key={key}
                    gridColumnStart={{
                      //@ts-expect-error key not inside offset
                      [Breakpoints.tablet]: 1 + key,
                      //@ts-expect-error key not inside offset
                      [Breakpoints.mobile]: 1 + key,
                    }}
                    gridColumnEnd={{
                      //@ts-expect-error key not inside offset
                      [Breakpoints.tablet]: 2 + key,
                      //@ts-expect-error key not inside offset
                      [Breakpoints.mobile]: 2 + key,
                    }}
                  >
                    <a className={css.link} href={elem.uri} target="_new">
                      <Img className={css.linkImg} alt={elem.type} src={socialIconImg[elem.type]} />
                      <div className={css.bg}></div>
                    </a>
                  </GridColumn>
                )
              })}
          </Grid>
        </div>
      </div>
    </GridColumn>
  )
}

export default InfoDescription
