import css from './NoEvents.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

const NoEvents: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={css.noEventsContainer}>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.tablet]: 4, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.tablet]: 9, [Breakpoints.mobile]: 17 }}
        >
          <Img className={css.image} alt={String(t(`collection:no_events_title`))} src="/collection/no_events.svg" />
        </GridColumn>
        <GridColumn
          gridColumnStart={{ [Breakpoints.tablet]: 10, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.tablet]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.textContainer}>
            <MediaQuery maxWidth={BreakpointsMaxWidth.landscape}>
              <h2 className={css.title}>{t(`collection:no_events_title`)}</h2>
            </MediaQuery>
            <MediaQuery minWidth={BreakpointsMaxWidth.landscape}>
              <h4 className={css.title}>{t(`collection:no_events_title`)}</h4>
            </MediaQuery>
            <p className={css.desc}>
              {t(`collection:no_events_description`)}
              <span className={css.tags}>{t(`collection:no_events_staytuned`)}</span>
            </p>
          </div>
        </GridColumn>
      </Grid>
    </div>
  )
}

export default NoEvents
