import css from './InfoBox.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CollectionAsset, CollectionInfoBoxDescription } from 'meta/pages/collection'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

import { MockCollectionAnchorable } from '../mock'
import Gallery from './Gallery'
import InfoDescription from './InfoDescription'

interface Props {
  bgImgUrl: string
  descData: CollectionInfoBoxDescription
  galleryData: CollectionAsset[]
  isPerformer?: boolean
}

const InfoBox: React.FC<Props & MockCollectionAnchorable> = (props) => {
  const { bgImgUrl, anchorId, descData, galleryData, isPerformer } = props

  return (
    <div className={css.infoBoxContainer}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.landscape}>
        <div className={css.galleryMobile}>
          <Gallery images={galleryData} descData={descData} />
        </div>
      </MediaQuery>
      <img className={classNames(css.bgImage, { [css.isPerformer]: isPerformer })} src={bgImgUrl}></img>
      <div className={css.bgBlackOverlay}></div>
      <GridContainer className={css.gridContainer}>
        <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
          <Grid>
            <InfoDescription {...descData} anchorId={anchorId} />
            <MediaQuery minWidth={BreakpointsMaxWidth.landscape}>
              <Gallery images={galleryData} descData={descData} />
            </MediaQuery>
          </Grid>
        </GridColumn>
      </GridContainer>
    </div>
  )
}

export default InfoBox
