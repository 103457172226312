import css from '../Images.module.scss'
import React, { useRef } from 'react'
import { useSwipeable } from 'react-swipeable'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionAsset, CollectionInfoBoxDescription } from 'meta/pages/collection'

import useSwiperScroll from 'components/BackendControlled/Component/Category/Showcase/Single/useSwiperScroll'
import { GridColumn } from 'components/Layout/Grid'

import Item from '../Item'

interface Props {
  images: CollectionAsset[]
  setVisible(num: number): void
  descData: CollectionInfoBoxDescription
}

const Mobile: React.FC<Props> = (props) => {
  const { images, setVisible, descData } = props

  const handlers = useSwipeable({
    onSwipedLeft: () => onNext(),
    onSwipedRight: () => onPrev(),
  })

  const swiperRef = useRef<HTMLDivElement>(null)
  const swiperEdges = useSwiperScroll(swiperRef)

  const scrollTo = (element: HTMLDivElement): void => {
    swiperRef.current?.scrollTo({ left: element.offsetLeft, behavior: 'smooth', top: 0 })
  }
  const onPrev = (): void => {
    if (swiperEdges.first?.node) scrollTo(swiperEdges.first.node)
  }

  const onNext = (): void => {
    if (swiperEdges.last?.node) scrollTo(swiperEdges.last.node)
  }

  return (
    <GridColumn
      className={css.gridContainer}
      gridColumnStart={{ [Breakpoints.tablet]: 10, [Breakpoints.mobile]: 1 }}
      gridColumnEnd={17}
    >
      <div ref={handlers.ref}>
        <div className={css.galleryContainer} ref={swiperRef}>
          {images.map((elem, key) => (
            <Item setVisible={setVisible} key={key} num={key} url={elem.url} descData={descData} />
          ))}
        </div>
      </div>
    </GridColumn>
  )
}

export default Mobile
