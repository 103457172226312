import { fetchData } from 'store/pages/performer/actions/fetchData'
import { fetchEvents } from 'store/pages/performer/actions/fetchEvents'
import { fetchMetadata } from 'store/pages/performer/actions/fetchMetadata'
import { fetchSeasons } from 'store/pages/performer/actions/fetchSeasons'

import { PerformerSlice } from '../slice'

export const PerformerActions = {
  ...PerformerSlice.actions,
  fetchData,
  fetchEvents,
  fetchSeasons,
  fetchMetadata,
}
