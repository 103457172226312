import { createAsyncThunk } from '@reduxjs/toolkit'

import { PerformerController } from 'controller/performer/controller'
import { DetailsParams } from 'store/pages/performer/slice/types'
import { RootState } from 'store/types'

type Params = {
  id: string
}

type Returned = {
  id: string
  details: DetailsParams
}

export const fetchData = createAsyncThunk<Returned, Params, { state: RootState }>(
  'performer/fetchData',
  async (params) => {
    const { id } = params

    const details = await PerformerController.getDetails({ id })

    return { id, details }
  }
)
