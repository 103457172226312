import { createAsyncThunk } from '@reduxjs/toolkit'

import { CollectionEvent } from 'meta/pages/collection'
import { PerformerController } from 'controller/performer/controller'
import { RootState } from 'store/types'

type Params = {
  id: string
}

type Returned = {
  id: string
  events: CollectionEvent[]
}

export const fetchEvents = createAsyncThunk<Returned, Params, { state: RootState }>(
  'performer/fetchEvents',
  async (params) => {
    const { id } = params

    const events = await PerformerController.getEvents({ id })

    return { id, events }
  }
)
