import { CollectionEvent, CollectionTypeTicket } from 'meta/pages/collection'
import { IPerformer } from 'meta/pages/performer'
import { SliceSelector } from 'store/types'

import { getPerformerKey } from '../_utils'

const getPerformerDetails: SliceSelector<IPerformer | undefined, { id: string; language: string }> =
  ({ id, language }) =>
  (state) =>
    state.pages.performer[getPerformerKey({ id, language })]?.details

const getPerformerEvents: SliceSelector<CollectionEvent[] | undefined, { id: string; language: string }> =
  ({ id, language }) =>
  (state) =>
    state.pages.performer[getPerformerKey({ id, language })]?.events

const getPerformerSeasons: SliceSelector<CollectionTypeTicket[] | undefined, { id: string; language: string }> =
  ({ id, language }) =>
  (state) =>
    state.pages.performer[getPerformerKey({ id, language })]?.seasons

const getPerformerMetadata: SliceSelector<object | undefined, { id: string; language: string }> =
  ({ id, language }) =>
  (state) =>
    state.pages.performer[getPerformerKey({ id, language })]?.metadata

export const PerformerSelectors = {
  getPerformerDetails,
  getPerformerEvents,
  getPerformerSeasons,
  getPerformerMetadata,
}
