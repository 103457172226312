import { ApiEndpoint } from 'controller/endpoint'
import { DetailsParams } from 'store/pages/performer/slice/types'
import { HttpRequest } from 'utils/http'

type Params = {
  id: string
}

export const getDetails = async (params: Params): Promise<DetailsParams> => {
  const { id } = params

  const { data } = await HttpRequest.get<DetailsParams>(ApiEndpoint.performer.details(id))

  return data
}
